<template lang="pug">
  #login.container-fluid
    .row.no-gutter
      #left-side.d-none.d-lg-block.col-lg-7.col-xl-8.px-lg-0
        carousel(
          :autoplay="false" 
          :perPage="1"
          :autoplayTimeout="4250"
          :autoplayHoverPause="false"
        )
          slide(v-for="img in carousleImages" :key="img")
            div.image(:style="'background: transparent url('+img+') no-repeat center;background-size: auto 100%'")
        .blurred-card
         
      .col-lg-5.col-xl-4
        .login.d-flex.align-items-center.py-5
          img.logo(height="52" class="ml-auto" src="@/assets/img/matador.png")
          .container
            .row
              .col-lg-10.mx-auto
                h3.login-heading.mb-3 Login
                p Selamat datang di Matador Tracker
                .wrong-login.off.px-1.py-1(v-if="missMatch" style="position:")
                  p.text-off() Login Failed
                  p.text-off.error(style=" text-transform: capitalize;") {{errorMessage}}                                  
                form(@submit="onLogin")
                  .form-group.mt-4.mb-4.pb-lg-2
                    label(for='inputEmail') Email
                    input#inputEmail.form-control(type='email' @input="missMatch=false" v-model="email" placeholder='Email' required='' autofocus='')
                  .form-group.mb-5(style="position: relative")
                    label(for='inputPassword') Kata Sandi
                    input#inputPassword.form-control(:type='passwordType' @input="missMatch=false" v-model="password" placeholder='Password' required='')
                    a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 10px;")
                      .text-muted(v-if="passwordType === 'password'")
                        eye-off
                      .text-primary(v-if="passwordType === 'text'")
                        eye-on
                  div.mb-5
                  
                    checkbox(:checked="keepLogin" @switch="keepLogin = !keepLogin")
                      p.m-0 Tetap Masuk
                  //- button.btn.btn-lg.btn-primary.btn-block.btn-login.font-weight-bold.mb-2(type='submit') Sign in

                  matador-button(:full="true" size="lg" nativeType="submit")
                    | Sign in
                p.mt-4(v-if="showCookie")
                  | Situs ini menggunakan cookie. Dengan melanjutkan, Anda menyetujui penggunaan cookie.
              //- a(@click="showCookie = false" href="javascript:;") Agree and dismiss
    feedback-modal(v-if="showFeedbackModal")
    verification-modal(v-if="showVerificationModal" @close='toggleVerificationModal' :user_id='verifUserId')
</template>

<script>
import { postLogin, postResendEmail } from "@/utils/api";
import EyeOff from "vue-material-design-icons/EyeOff.vue";
import EyeOn from "vue-material-design-icons/Eye.vue";
import FeedbackModal from "../../../components/Matador/FeedbackModal.vue";
import VerificationModal from "../../../components/Matador/Register/VerificationModal.vue";
import Bugsnag from "@bugsnag/js";
import { mapGetters } from "vuex";
import { result } from "lodash";
export default {
  data() {
    return {
      verifUserId: "",
      missMatch: false,
      errorMessage: "",
      showCookie: true,
      email: "",
      password: "",
      passwordType: "password",
      keepLogin: false,
      currentIndex: 0,
      timeout: null,
      carousleImages: [
        "https://storage.googleapis.com/matador-tracker/vehicles/dashboard/splash-image-1.jpg",
        "https://storage.googleapis.com/matador-tracker/vehicles/dashboard/spash-image-2.jpg",
      ],
    };
  },
  components: {
    VerificationModal,
    EyeOff,
    EyeOn,
    FeedbackModal,
  },
  computed: {
    ...mapGetters({
      statusLogin: "gettersStatusLogin",
    }),
    auth() {
      return this.$store.state.auth;
    },
    afterLoginPath() {
      console.log("after +" + this.$store.state.afterLoginPath);
      return this.$store.state.afterLoginPath;
    },
    showFeedbackModal() {
      return this.$store.state.showFeedbackModal;
    },
    showVerificationModal() {
      return this.$store.state.showVerificationModal;
    },
  },
  watch: {
    currentIndex: {
      immediate: true,
      handler: function (index) {
        this.timeout = setTimeout(() => {
          if (index + 1 == this.carousleImages.length) this.currentIndex = 0;
          else this.currentIndex = index + 1;
          document
            .getElementsByClassName("VueCarousel-dot")
            [this.currentIndex].click();
        }, 5000);
      },
    },
  },
  methods: {
    toggleVerificationModal() {
      this.$store.commit(
        "setShowVerificationModal",
        !this.$store.state.showVerificationModal
      );
    },
    resendEmail() {
      console.log(this.verifUserId, "test");
      postResendEmail({
        user_id: this.verifUserId,
      }).then(({ data: { code, messgae, status } }) => {});
      this.toggleVerificationModal;
    },
    loginGuest() {
      const email = "guest@mailinator.com";
      const password = "komitmen";
      try {
        postLogin({ email, password }).then(
          ({ data: { code, result: userData, messgae, status } }) => {
            if (code == 200) {
              // localStorage.setItem("user_id", userData.user_id);
              this.$store.commit("setAuth", userData);
              this.$store.commit("setSidebar", userData.access);
              this.$cookies.set("token", userData.token, "1h");
              this.$cookies.set("user_id", userData.user_id, "1h");
              this.$cookies.set("role", userData.role, "1h");
              if (!localStorage.getItem("theme-color")) {
                localStorage.setItem("theme-color", "theme-blue");
                this.$store.commit("setTheme", "theme-blue");
              }
              setTimeout(() => this.$router.replace("/catalogue"), 500);
              Bugsnag.setUser(userData.user_id, userData.email, userData.name);
              clearTimeout(this.timeout);
            } else {
              this.errorMessage = messgae;
              this.missMatch = true;
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    onLogin(event) {
      console.log(event);
      event.preventDefault();
      const { email, password } = this;
      try {
        postLogin({ email, password }).then(
          ({ data: { code, result: userData, messgae, status } }) => {
            if (code == 200) {
              localStorage.setItem("user_id", userData.user_id);
              console.log(this.$route.name, "here");
              console.log(userData.organization_id, "org");
              this.$store.dispatch("haveToLogin", false);
              this.$store.dispatch(
                "setAfterLoginPathAction",
                userData.role != "guest" && userData.role != "user"
                  ? userData.role == "superadmin"
                    ? "/superboard"
                    : "/dashboard"
                  : userData.role == "user" && userData.organization_id == null
                  ? "/registration"
                  : "/catalogue"
              );
              console.log(this.$store.state.afterLoginPath, "show");
              this.$store.commit("setAuth", userData);
              this.$store.commit("setSidebar", userData.access);
              this.$cookies.set(
                "keepLogin",
                this.keepLogin ? "yes" : "no",
                "1y"
              );
              this.$cookies.set(
                "token",
                userData.token,
                this.keepLogin ? "1y" : "1h"
              );
              this.$cookies.set(
                "user_id",
                userData.user_id,
                this.keepLogin ? "1y" : "1h"
              );
              this.$cookies.set(
                "role",
                userData.role,
                this.keepLogin ? "1y" : "1h"
              );
              if (!localStorage.getItem("theme-color")) {
                localStorage.setItem("theme-color", "theme-blue");
                this.$store.commit("setTheme", "theme-blue");
              }
              setTimeout(() => this.$router.replace(this.afterLoginPath), 500);
              Bugsnag.setUser(userData.user_id, userData.email, userData.name);
              clearTimeout(this.timeout);
            } else {
              if (userData.user_id) {
                this.errorMessage = messgae;
                this.missMatch = true;
                localStorage.setItem("user_id", userData.user_id);
                postResendEmail({ user_id: userData.user_id }).then(
                  ({ data: { code, messgae, status } }) => {
                    if (code == 200) {
                      this.$toast.open(
                        "Your Verification Email Successfully Sent"
                      );
                    } else {
                      this.$toast.error(
                        "Your Verification Email Failed to Sent"
                      );
                    }
                  }
                );
                this.toggleVerificationModal();
              } else {
                this.errorMessage = messgae;
                this.missMatch = true;
              }
            }
          }
        );
      } catch (error) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    },
    clearGuestData() {
      if (this.$cookies.get("role") == "guest") {
        this.$cookies.remove("token");
        this.$cookies.remove("user_id");
        this.$cookies.remove("role");
      }
    },
    togglePassword() {
      this.passwordType = this.passwordType == "text" ? "password" : "text";
    },
    //how to know browser we are using, now
    setBrowserClasses() {
      if (typeof InstallTrigger !== "undefined") {
        var x = document.getElementsByTagName("BODY")[0];
        x.classList.add("firefox");
        // console.log("benar")
      }
    },
  },
  mounted() {
    // setInterval(() => {
    //   document.getElementsByClassName('VueCarousel-dot')[0].click();
    // }, 16500);

    if (this.statusLogin) {
      this.$toast.error("You Must Have Account To Rent Vehicle");
    }
    this.setBrowserClasses();
    // this.clearGuestData();
  },
};
</script>

<style lang="scss">
:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

#login {
  .login,
  .image {
    min-height: 100vh;
    position: relative;
  }

  .login img.logo {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .login .cookie {
    position: absolute;
    bottom: 0;
    max-width: 90%;
  }

  .login .cookie a {
    color: #992024;
    text-decoration: underline;
    font-weight: 500;
  }

  .bg-image {
    background-image: url("/login-bg.png");
    background-size: cover;
    background-position: center;
  }

  .login-heading {
    font-weight: 900;
    font-size: 2rem;
  }

  .btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    border-radius: 2rem;
    border-color: #992024 !important;
    background-color: #992024 !important;
  }

  .btn-login:hover {
    border-color: #6b0e11 !important;
    background-color: #6b0e11 !important;
  }

  .VueCarousel-slide {
    position: relative;
    background: black;
    color: #fff;
    font-family: Arial;
    font-size: 24px;
    text-align: center;
    height: 100vh;
    width: 500px;
    display: flex;
    .image {
      width: 100%;
      height: 100vh;
      // background-size: auto 100%;
    }
  }

  .VueCarousel-pagination {
    position: absolute;
    bottom: 20px;
    text-align: left;
    padding-left: 20px;
    .VueCarousel-dot {
      padding: 9px !important;
      width: 16px !important;
      height: 16px !important;
    }
    .VueCarousel-dot--active {
      width: 80px !important;
      border-radius: 30px;
      background-color: #2B548C !important;
    }
  }

  #left-side {
    position: relative;
    .overlay-card {
      position: absolute;
      bottom: 80px;
      left: 30px;
      border-radius: 30px;
      background: linear-gradient(
        349.44deg,
        rgba(255, 255, 255, 0.018) 5.78%,
        rgba(99, 99, 99, 0.156) 79.88%,
        rgba(255, 255, 255, 0.018) 100%
      );
      backdrop-filter: blur(20px);
      border-radius: 40px;
      padding: 30px;
      width: 560px;
      height: 250px;
      color: #ffffff;
      h2 {
        margin-top: 0;
        font-weight: bolder;
      }
      p {
        max-width: 50%;
      }
      .btn {
        font-size: 1.4rem;
        border-radius: 14px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .text-off {
    color: #992024;
    &.error {
      font-weight: 500;
    }
  }
  .wrong-login {
    // width: 94%;
    height: 70px;
    border-radius: 5px;

    &.off {
      background-color: #f0ddde;
    }
  }
  @media screen and (min-width: 1024px) {
    overflow: hidden;
    html,
    body {
      overflow: hidden;
    }
  }
}
</style>
